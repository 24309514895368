<!--
 * @Author: SongYijie
 * @Date: 2020-04-24 11:15:55
 * @LastEditors: SongYijie
-->
<!--  -->
<template>
  <div class="main has-header">
    <mt-header fixed title="联系我们">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="goBack"></mt-button>
      </router-link>
    </mt-header>
    <ul class="info">
      <li>
        联系电话：
        <a href="tel:0571-88058088" class="call">0571-88058088</a>
        <p>(工作日：9：00-12：00，13：30-18：00)</p>
      </li>
      <li>邮箱：{{isXyk ?'wusut@kylin-cloud.com.cn':'wusut@zhongxicz.com'}}</li>
    </ul>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    //这里存放数据
    return {
      isXyk:window.location.origin.indexOf('h5.kylincloud-saas') > -1
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goBack() {
      this.$router.back(-1)
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
ul {
  border-top: 8px solid #f5f5f5;
  li {
    border-bottom: 8px solid #f5f5f5;
    padding: 12px 20px;
    line-height: 1.8re;
    font-size: 1rem;
  }
}
</style>